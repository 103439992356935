



















































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'EvaluatingQuestion',
})
export default class EvaluatingQuestionItem extends Vue {
  public currentPage4 = 4;
  public form = {
    topic: '',
    category: '',
  };
  public onSubmit() {
    console.log(1);
  }
  public tableData = [
    {
      topic: '您的之息亲属中(父母亲，兄弟姐妹)目前或曾经是否有一下明确诊断疾病',
      name: '肺癌',
      weight: '2',
      illness: '呼吸系统',
      sort: 1,
      genre: '影像检查',
    },
  ];
  public editRow(): void {
    console.log(1);
  }
  public deleteRow(index: number, rows: any): void {
    rows.splice(index, 1);
  }
  public handleSizeChange(val: any): void {
    console.log(`每页 ${val} 条`);
  }
  public handleCurrentChange(val: any): void {
    console.log(`当前页: ${val}`);
  }
}
